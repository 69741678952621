/* global lozad, GLightbox, toastr */

$(function () {
  // Lazy load
  const observer = lozad()
  observer.observe()

  // Lightbox
  GLightbox()
  if ($('.description a').length || $('#tab-description a').length) {
    GLightbox({
      selector:
        '.description a[href$=".jpeg"], .description a[href$=".jpg"], .description a[href$=".png"], .description a[href$=".gif"], #tab-description a[href$=".jpeg"], #tab-description a[href$=".jpg"], #tab-description a[href$=".png"], #tab-description a[href$=".gif"]',
    })
  }

  // Search
  $("#search input[name='search'], #searchMobile input[name='search']")
    .parent()
    .find('button')
    .on('click', function () {
      let url = $('base').attr('href') + 'index.php?route=product/search'

      let value = $("#search input[name='search']").val()

      if (!value) {
        value = $("#searchMobile input[name='search']").val()
      }

      if (value) {
        url += '&search=' + encodeURIComponent(value)
      }

      location = url
    })

  $("#search input[name='search'], #searchMobile input[name='search']").on(
    'keydown',
    function (e) {
      if (e.keyCode == 13) {
        $("#search input[name='search'], #searchMobile input[name='search']")
          .parent()
          .find('button')
          .trigger('click')
      }
    }
  )

  // Ajax search
  $(
    "#search input[name='search'], #searchMobile input[name='search']"
  ).autocomplete({
    source: function (request, response) {
      if (request.length > 1) {
        $.ajax({
          url:
            'index.php?route=product/search_autocomplete&filter_name=' +
            encodeURIComponent(request),
          dataType: 'json',
          success: function (json) {
            response(
              $.map(json.products, function (item) {
                let productblock
                if (item['product_special']) {
                  productblock = `${item['product_name']}<br><small>${item['product_special']}<span class="price-old">${item['product_price']}</span></small>`
                } else {
                  productblock = `${item['product_name']}<br><small>${item['product_price']}</small>`
                }

                return {
                  label: productblock,
                  search_name: item['product_name'],
                  value: encodeURIComponent(item['product_name']),
                }
              })
            )
          },
        })
      }
    },
    select: function (item) {
      var product_name = $('<textarea />').html(item['search_name']).text()
      $("#search input[name='search'], #searchMobile input[name='search']").val(
        product_name
      )

      $("#search input[name='search'], #searchMobile input[name='search']")
        .parent()
        .find('button')
        .trigger('click')
    },
  })

  // Product List
  $('#list-view').on('click', function () {
    $('#products > .product-grid').attr(
      'class',
      'product-layout product-list col-xs-12'
    )
    $('#grid-view').removeClass('active')
    $('#list-view').addClass('active')

    localStorage.setItem('display', 'list')
  })

  // Product Grid
  $('#grid-view').on('click', function () {
    // What a shame bootstrap does not take into account dynamically loaded columns
    var cols = $('#column-right, #column-left').length

    if (cols == 2) {
      $('#products .product-list').attr(
        'class',
        'product-layout product-grid col-sm-6'
      )
    } else if (cols == 1) {
      $('#products .product-list').attr(
        'class',
        'product-layout product-grid col-lg-4 col-sm-6'
      )
    } else {
      $('#products .product-list').attr(
        'class',
        'product-layout product-grid col-lg-3 col-md-4 col-sm-6'
      )
    }

    $('#list-view').removeClass('active')
    $('#grid-view').addClass('active')

    localStorage.setItem('display', 'grid')
  })

  if (localStorage.getItem('display') == 'list') {
    $('#list-view').trigger('click')
    $('#list-view').addClass('active')
  } else {
    $('#grid-view').trigger('click')
    $('#grid-view').addClass('active')
  }

  $('.block-quantity')
    .on('click', '.btn-plus', function () {
      const inputField = $(this)
        .closest('.block-quantity')
        .find('.input-quantity')
      cart.plus(inputField)
    })
    .on('click', '.btn-minus', function () {
      const inputField = $(this)
        .closest('.block-quantity')
        .find('.input-quantity')
      cart.minus(inputField)
    })
    .on('change', '.input-quantity', function () {
      const inputField = $(this)
      cart.change(inputField)
    })
})

// Cart
const cart = {
  plus: function (qtyEl) {
    // const qtyEl = $('#product-quantity' + product_id)
    let qtyVal = parseInt(qtyEl.val().match(/\d+/g), 10)

    const stock = parseInt(qtyEl.data('stock'), 10)

    if (!Number.isNaN(qtyVal)) {
      if (qtyVal >= stock) {
        toastr.error('Осталось ' + stock + 'ед.')
      } else {
        qtyEl.val(++qtyVal)
      }
    } else {
      qtyEl.val('1')
    }
  },

  minus: function (qtyEl) {
    // const qtyEl = $('#product-quantity' + product_id)
    let qtyVal = parseInt(qtyEl.val().match(/\d+/g), 10)

    const stock = parseInt(qtyEl.data('stock'), 10)

    if (qtyVal > 1 && !Number.isNaN(qtyVal)) {
      if (qtyVal > stock) {
        toastr.error('Осталось ' + stock + 'ед.')
        qtyEl.val(stock)
      } else {
        qtyEl.val(--qtyVal)
      }
    } else {
      qtyEl.val('1')
    }
  },

  change: function (qtyEl) {
    let qtyVal = parseInt(qtyEl.val().match(/\d+/g), 10)

    const stock = parseInt(qtyEl.data('stock'), 10)

    if (qtyVal > 1 && !Number.isNaN(qtyVal)) {
      if (qtyVal > stock) {
        toastr.error('Осталось ' + stock + 'ед.')
        qtyEl.val(stock)
      } else {
        qtyEl.val(qtyVal)
      }
    } else {
      qtyEl.val('1')
    }
  },

  add: function (product_id, el) {
    let quantity = 1
    const quantityInput = $(el)
      .closest('.card-body')
      .find('.input-quantity')
      .val()
    if (quantityInput) {
      quantity = parseInt(quantityInput.match(/\d+/g), 10)
    }

    $.ajax({
      url: 'index.php?route=checkout/cart/add',
      type: 'post',
      data:
        'product_id=' +
        product_id +
        '&quantity=' +
        (typeof quantity != 'undefined' ? quantity : 1),
      dataType: 'json',
      success(json) {
        $('.alert, .text-danger').remove()

        if (json['redirect']) {
          location = json['redirect']
        }

        if (json['success']) {
          toastr.success(json['success'])
          // Need to set timeout otherwise it wont update the total
          setTimeout(function () {
            $('header #cart-total').html(json['total'])
          }, 100)

          $('#cart > ul').load('index.php?route=common/cart/info ul li')
        }
      },
    })
  },
  remove: function (key) {
    $.ajax({
      url: 'index.php?route=checkout/cart/remove',
      type: 'post',
      data: 'key=' + key,
      dataType: 'json',
      success: function (json) {
        // Need to set timeout otherwise it wont update the total
        setTimeout(function () {
          $('header #cart-total').html(json['total'])
        }, 100)

        $('#cart .dropdown-menu').load(
          'index.php?route=common/cart/info .dropdown-menu li'
        )
      },
    })
  },
}

// Autocomplete
;(function ($) {
  $.fn.autocomplete = function (option) {
    return this.each(function () {
      this.timer = null
      this.items = new Array()

      $.extend(this, option)

      $(this).attr('autocomplete', 'off')

      // Focus
      $(this).on('focus', function () {
        this.request()
      })

      // Blur
      $(this).on('blur', function () {
        setTimeout(
          function (object) {
            object.hide()
          },
          200,
          this
        )
      })

      // Keydown
      $(this).on('keydown', function (event) {
        switch (event.keyCode) {
          case 27: // escape
            this.hide()
            break
          default:
            this.request()
            break
        }
      })

      // Click
      this.click = function (event) {
        event.preventDefault()

        const value = $(event.target).parent().attr('data-value')

        if (value && this.items[value]) {
          this.select(this.items[value])
        }
      }

      // Show
      this.show = function () {
        var pos = $(this).position()

        $(this)
          .siblings('ul.dropdown-menu')
          .css({
            top: pos.top + $(this).outerHeight(),
            left: pos.left,
          })

        $(this).siblings('ul.dropdown-menu').show()
      }

      // Hide
      this.hide = function () {
        $(this).siblings('ul.dropdown-menu').hide()
      }

      // Request
      this.request = function () {
        clearTimeout(this.timer)

        this.timer = setTimeout(
          function (object) {
            object.source($(object).val(), $.proxy(object.response, object))
          },
          200,
          this
        )
      }

      // Response
      this.response = function (json) {
        let html = ''

        if (json.length) {
          for (let i = 0; i < json.length; i++) {
            this.items[json[i]['value']] = json[i]
          }

          for (let i = 0; i < json.length; i++) {
            if (!json[i]['category']) {
              html += `<li data-value="${json[i]['value']}"><a href="#">${json[i]['label']}</a></li>`
            }
          }

          // Get all the ones with a categories
          var category = new Array()

          for (let i = 0; i < json.length; i++) {
            if (json[i]['category']) {
              if (!category[json[i]['category']]) {
                category[json[i]['category']] = new Array()
                category[json[i]['category']]['name'] = json[i]['category']
                category[json[i]['category']]['item'] = new Array()
              }

              category[json[i]['category']]['item'].push(json[i])
            }
          }

          for (let i in category) {
            html +=
              '<li class="dropdown-header">' + category[i]['name'] + '</li>'

            for (let j = 0; j < category[i]['item'].length; j++) {
              html +=
                '<li data-value="' +
                category[i]['item'][j]['value'] +
                '"><a href="#">&nbsp;&nbsp;&nbsp;' +
                category[i]['item'][j]['label'] +
                '</a></li>'
            }
          }
        }

        if (html) {
          this.show()
        } else {
          this.hide()
        }

        $(this).siblings('ul.dropdown-menu').html(html)
      }

      $(this).after('<ul class="dropdown-menu" style="display: none;"></ul>')
      $(this)
        .siblings('ul.dropdown-menu')
        .delegate('a', 'click', $.proxy(this.click, this))
    })
  }
})(window.jQuery)
